.react-datepicker__navigation {
    align-items: start;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 24px;
    width: 32px;
    text-indent: -999em;
}
.react-datepicker__navigation-icon::before {
    border-color: #a5a5a5;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}

.react-datepicker__day-names, .react-datepicker__week {
    white-space: nowrap;
    height: 32px;
}